<template>
    <div class="content-top flex-fill">
        <div class="left">
            <div class="trading-chart">
                <!-- ticker -->
                <div class="ticker bg-title text-content">
                    <div stop="1" class="symbol-name">
                        <div class="d-flex flex-row">
                            <div class="dropdown">
                                <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    {{ $t('general.boption') }}
                                </button>
                                <div class="dropdown-menu">
                                    <router-link to="/futures" class="dropdown-item">{{ $t('general.futures') }}</router-link>
                                    <router-link to="/exchange" class="dropdown-item">{{ $t('general.exchange') }}</router-link>
                                    <router-link to="/boption" class="dropdown-item">{{ $t('general.boption') }}</router-link>
                                </div>
                            </div>
                            <div class="dropdown">
                                <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    {{ symbol.metadata.name }}
                                </button>
                                  <div class="dropdown-menu  side_right">
                                    <div class="menu_contet">
                                        <div class="input-group">
                                            <div class="input-group-text">
                                                <svg viewBox="0 0 1024 1024" width="32" height="32">
                                                    <path d="M945.066667 898.133333l-189.866667-189.866666c55.466667-64 87.466667-149.333333 87.466667-241.066667 0-204.8-168.533333-373.333333-373.333334-373.333333S96 264.533333 96 469.333333 264.533333 842.666667 469.333333 842.666667c91.733333 0 174.933333-34.133333 241.066667-87.466667l189.866667 189.866667c6.4 6.4 14.933333 8.533333 23.466666 8.533333s17.066667-2.133333 23.466667-8.533333c8.533333-12.8 8.533333-34.133333-2.133333-46.933334zM469.333333 778.666667C298.666667 778.666667 160 640 160 469.333333S298.666667 160 469.333333 160 778.666667 298.666667 778.666667 469.333333 640 778.666667 469.333333 778.666667z" fill="#666666" p-id="2868"></path>
                                                </svg>
                                            </div>
                                            <input type="text" class="form-control" v-model="search_term" placeholder="" />
                                        </div>
                                        <div class="futures-content">
                                            <router-link v-for="sym in filteredSymbols" :key="sym.id" :to="'/boption/' + sym.symbol.toLowerCase()" class="dropdown-item">
                                            {{ sym.name }}
                                        </router-link>
                                       </div>
                                   </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div growing-ignore="true" v-bind:class="'price-container ' + (symbol.up ? 'color-up' : 'color-down')+(['en','ja','ru'].includes($i18n.locale)?'small-size':'')">
                        <div :class="(['de','fr','th'].includes($i18n.locale)?'middle-size':'')">{{ symbol.price }}</div>
                        <div class="price-change">{{ symbol.price_change }}</div>
                    </div>
                    <dl growing-ignore="true" class="change d-none d-sm-block">
                        <dt class="text-label">{{ $t('boption.24h_change') }}</dt>
                        <dd v-bind:class="symbol.up ? 'color-up' : 'color-down'">{{ symbol.price_change_pct }}</dd>
                    </dl>
                    <dl growing-ignore="true" class="low d-none d-md-block">
                        <dt class="text-label">{{ $t('boption.24h_low') }}</dt>
                        <dd>{{ symbol.day_low }}</dd>
                    </dl>
                    <dl growing-ignore="true" class="high d-none d-md-block">
                        <dt class="text-label">{{ $t('boption.24h_high') }}</dt>
                        <dd>{{ symbol.day_high }}</dd>
                    </dl>
                    <dl growing-ignore="true" :class="'current-balance'+(['en','ja','ru'].includes($i18n.locale)?'small-size':'')">
                        <dt class="text-label">{{ $t('boption.label_balance') }}</dt>
                        <dd :class="'color-up'+(['de','fr','th'].includes($i18n.locale)?'middle-size':'')">{{ current_balance }}</dd>
                    </dl>
                </div>

                <!-- order options -->
                <div class="container-fluid bg-title text-content selected-order-options">
                    <div class="row">
                        <div class="col-10">
                            <div class="row">
                                <div class="col text-center">
                                    <h2 class="text-label">{{ $t('boption.label_amount') }}</h2>
                                    <!-- {{ boptionOrder.amount }} -->

                                    <span v-if="boptionOrder.useCoupons === true">{{ $t('orders.label_currency_coupon') }}</span>
                                    <span v-else>{{ boptionOrder.amount }}</span>
                                </div>
                                <div class="col text-center">
                                    <h2 class="text-label">{{ $t('boption.label_duration') }}</h2>
                                    {{ boptionOrder.duration }}s
                                </div>
                                <div class="col text-center">
                                    <h2 class="text-label">{{ $t('boption.label_profit_rate') }}</h2>
                                    <b> {{ boptionOrder.profitRate }}</b>
                                </div>
                            </div>
                        </div>
                        <div class="col-2 text-center">
                            <button class="btn btn-primary" v-on:click="showOrderOptions = true"><i class="fa fa-arrow-down"></i></button>
                        </div>
                    </div>
                </div>

                <!-- chart -->
                <div class="trading-chart-container bg-content">
                    <div class="chart-container">
                        <div id="tv_chart_container" class="chart">
                            <!-- Display the loading indicator by default -->
                            <loading-indicator />
                        </div>
                    </div>
                </div>
            </div>
            <div class="bottom-trade bg-title">
                <div class="row">
                    <!-- <div class="col-6 text-label">
                        <a href="javascript:">
                            <div class="row">
                                <dl class="col">
                                    <dt>{{ $t('boption.label_amount') }}</dt>
                                    <dd>{{ boptionOrder.amount }}</dd>
                                </dl>
                                <dl class="col">
                                    <dt>{{ $t('boption.label_duration') }}</dt>
                                    <dd>{{ boptionOrder.duration }}s</dd>
                                </dl>
                                <dl class="col">
                                    <dt>{{ $t('boption.label_profit_rate') }}</dt>
                                    <dd>{{ boptionOrder.profitRate }}</dd>
                                </dl>
                                <div class="indicator">
                                    <i class="fas fa-angle-down"></i>
                                </div>
                            </div>
                        </a>
                    </div> -->

                    <!-- Create order buttons -->
                    <div class="col">
                        <button class="btn-submit bg-buy" @click="triggerCreateOrder(true)">{{ $t('boption.label_buy_up') }}</button>
                    </div>
                    <div class="col">
                        <button class="btn-submit bg-sell" @click="triggerCreateOrder(false)">{{ $t('boption.label_buy_down') }}</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="micro-trade" :class="{ 'slide-out': !showOrderOptions }">
            <div class="mod bg-content">
                <div class="mod-head tabs bg-title text-label">
                    {{ $t('boption.label_balance') }}
                </div>
                <div class="mod-body current-balance text-content">
                    <div class="ticker text-content">
                        <dl growing-ignore="true" class="balance">
                            <dd class="color-up">
                                {{ current_balance }} <small>{{ currencySymbol }}</small>
                            </dd>
                        </dl>
                    </div>
                    <div class="actions">
                        <router-link to="/user/balance">{{ $t('general.deposit') }}</router-link>
                        <router-link to="/finance/withdraw">{{ $t('general.withdraw') }}</router-link>
                    </div>
                </div>
            </div>

            <!-- Order options -->
            <order-options :boptionConfig="boptionConfig" :boptionOrder="boptionOrder" @create-order="triggerCreateOrder" @close-options="onCloseOrderOptions" />
        </div>
    </div>
</template>

<style scoped>
@media (min-width: 768px) {
    .selected-order-options {
        display: none;
    }
}
@media (max-width: 768px) {
    .small-size>div,.small-size>dt,.small-size>dd{
        font-size: 10px!important;
    }
    .middle-size{
        font-size: 14px!important;
    }
    .ticker .price-container {
        margin-right: 16px;
    }
}

.selected-order-options {
    font-size: 1.5rem;
    line-height: 1.5;
}

.selected-order-options h2 {
    font-size: 1rem;
    margin: 0;
    line-height: 1.5;
}

.selected-order-options .btn {
    margin-top: 0.5rem;
    min-width: 40px;
    height: 40px;
    width: 40px;
}
</style>

<script>
import { getSymbolManagerAsync } from 'utilities/helper';
import * as chart from 'utilities/QuoteDataFeed.js';
import { Dropdown } from 'bootstrap';

import OrderOptions from './BOption_OrderOptions.vue';

// The date time when the last quote is received.
let g_lastQuoteTime = new Date();

export default {
    components: { OrderOptions },
    props: ['symbol', 'boptionConfig', 'boptionOrder'],

    data() {
        return {
            // All binary option symbols
            symbols: [],

            // The current available balance.
            current_balance: '--',

            // Indicates whether to display order options or not (for mobile version)
            showOrderOptions: false,

            // The version of the current chart.
            chartVersion: 0,

            // Indicates whether the component has been destoyed or not.
            destroyed: false,
            search_term:null
        };
    },
    computed: {
        filteredSymbols: function () {
            if (!this.symbols) {
                return [];
            }
            const term =
                this.search_term && typeof this.search_term === 'string'
                    ? this.search_term.trim().toUpperCase() // Always use upper case
                    : '';     
            if (term && term.length > 0) {
                let data = this.symbols.filter((s) => {
                    return s.name.indexOf(term) >= 0;
                });
                return data
            }else{
               return  this.symbols
            } 
        }
    },
    created() {
        this.destroyed = false;

        this.getSymbols();
        this.syncBalance();
    },

    mounted() {
        this.initChartAsync();

        const dropdownElementList = document.querySelectorAll('.dropdown-toggle')
        const dropdownList = [...dropdownElementList].map(elm => {
            $(elm).click(() => {
                const d = Dropdown.getOrCreateInstance(elm);
                d.show();
            });
        });
    },

    beforeDestroy() {
        console.log(`## Destroy the binary option page.`);
        this.destroyed = true;

        // Stop socket io connections
        $.closeSocketIo();
        chart.destroyChart();
    },

    methods: {
        initChartAsync: async function () {
            const sym = this.symbol;
            const self = this;

            // Clear any existing klines
            chart.setInitialKlines(null);

            // Read initial klines
            const end_ts = Math.floor(new Date().setSeconds(0, 0) / 1000);
            const from_ts = end_ts - 1500 * 60; // request klines for past 1500 minutes.
            const request_url = `/api/v1/quotation/klines?id=${encodeURIComponent(sym.metadata.id)}&type=1&from=${from_ts}&to=${end_ts}&limit=1500`;

            try {
                const resp = await $.callPostApi(self, request_url);
                if (resp) {
                    chart.setInitialKlines(resp);
                }
            } catch (err) {
                console.log(`Failed to read initial klines: ${err}`);
            }

            // Initialize the tradingview chart
            chart.initializeChart(sym, {
                region: this.sysconfig.region,
                locale: this.$i18n.locale,
                uiVersion: this.$version,
                defaultInterval: this.boptionConfig.defaultInterval,
                tzOffset: this.sysconfig.tzOffset
            });

            g_lastQuoteTime = new Date();
            const chartVersion = ++this.chartVersion;

            // Start a socket io connection
            $.initSocketIo('/m' + this.symbol.metadata.id, (quote) => {
                if (self.destroyed === false) {
                    if (self.chartVersion !== chartVersion) {
                        return;
                    }

                    // Is there a big gap for the latest quote?
                    let now = new Date();
                    const gap = now - g_lastQuoteTime;
                    if (gap > 300 * 1000) {
                        setTimeout(() => {
                            $.closeSocketIo();
                            self.initChartAsync();
                        }, 0);
                        return;
                    } else {
                        g_lastQuoteTime = now;

                        sym.updateRtqs(quote);
                        chart.updateRtqsToChart(quote);
                    }
                }
            });
        },

        getSymbols: function () {
            const self = this;
            getSymbolManagerAsync().then((mgr) => {
                // 1: boption symbols
                self.symbols = mgr.getSymbols(1);
            });
        },

        syncBalance: function () {
            const self = this;
            $.callPostApi(self, '/api/v1/balance/get').then((json) => {
                if (json) {
                    self.current_balance = (json.balance + json.frozen).toFixed(2);
                } else {
                    self.current_balance = '--';
                }
            });
        },

        triggerCreateOrder: function (direction) {
            this.showOrderOptions = false;
            this.$emit('create-order', direction);
        },

        onCloseOrderOptions: function () {
            this.showOrderOptions = false;
        }
    }
};
</script>
<style scoped>
.content-top{
    position:relative;
}
.content-top .dropdown{
    position: initial;
}
.side_right{
    left: -70px !important;
    background: #fff;
    color: #000;
    z-index: 999999999;
    overflow: hidden;
    width: 200px;
    transition: all 0.5s ease-in-out;
    border: 1px solid #ddd;
}
.futures-content{
   overflow: auto;
   height: 100vh;
}

.futures-content a,.side_right .form-control{
   color: #000;
}
.futures-content a:hover{
   background: #0A2B9A ;
   color: #fff;
}
.bg-content input{
    border: 1px solid #E1E2E6;
}
.trade-layout a {
   font-size: 14px;
}
.input-group{
    width: 80%;
    margin-top: 20px;
    margin-bottom: 10px;
    margin-left: 20px;
}
.input-group  .input-group-text,.input-group  .form-control{
    height: 30px;
    background: #fff;
    border: 1px solid #1a1c24;
}
.input-group  .input-group-text{
    width: 40px;
    height: 30px;
    background: #fff;
    border: 1px solid #1a1c24;
}
.input-group .form-control:focus{
    border: none;
}
.form-control{
    color: #7e8697;
}
</style>