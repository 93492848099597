<template>
    <section class="page settings-page">
        <template v-if="profile">
            <section class="user-top-header container">
                <div class="d-flex align-items-center">
                    <img :src="profile.avatarUrl || require('@/assets/images/vip/userAvatar.png')" alt="User Avatar" class="avatar" @click="triggerFileInput">
                    <input type="file" ref="avatarInput" @change="uploadAvatar" accept="image/*" class="hidden-input"/>
                    <div class="user-info-box">
                        <div class="user-box">
                            <div class="vip-box">
                                <div class="username">{{ formatString(this.profile.realName) }}</div>
                                <div v-if="profile.vipLevel > 0" class="d-flex align-items-center vip-label">
                                    <img :src='"@/assets/images/vip/" + profile.vipLevel + ".png"' alt="" class="vip-img" />
                                </div>
                                <div class="checkin-box" v-if="sysconfig.luckydraw && sysconfig.luckydraw.enabled"> 
                                    <button  type="button" class="btn btn-primary btn-sm btn-lucky" @click="luckyDraw()">{{$t("lucky.title")}}</button>
                                </div>
                                <div class="checkin-box" v-if="sysconfig.checkinEnabled"> 
                                        <button  type="button" class="btn btn-primary btn-sm btn-checkin"  @click="isShowPC = true">
                                            {{ hasChecked?$t("coin_treasure.signed_in"):$t("coin_treasure.sign_in") }}
                                        </button>
                                </div>
                          </div>
                        </div>
                    </div>
                </div>
                <div class="user-info d-block d-md-none">
                    <div class="user-info-item">
                        <span class="info-des">{{ $t('user.label_account') }}:</span>
                        <div v-if="userPhone" class="info-value">{{ userPhone }}</div>
                        <router-link v-else class="user-link"  to="/user/phone">{{ $t('ga.status_unset') }}</router-link>
                    </div>
                    <div class="user-info-item">
                        <span class="info-des">{{ $t('register.label_email') }}:</span>
                        <div v-if="userEmail" class="info-value">{{ userEmail }}</div>
                        <router-link v-else class="user-link"  to="/user/email">{{ $t('ga.status_unset') }}</router-link>
                    </div>
                    <div class="user-info-item">
                        <span class="info-des">{{ $t('ga.title') }}:</span>
                        <div v-if="userGa" class="info-value">{{ userGa}}</div>
                        <router-link v-else class="user-link"  to="/user/ga">{{ $t('ga.status_unset') }}</router-link>
                    </div>
                </div>
            </section>
            <div v-if="sysconfig.checkinEnabled" class="checkin-container checkin-container-mobile">
                <div class="desc">{{ $t("coin_treasure.signed_in_daynum",{numConsecutiveDays:numConsecutiveDays}) }}</div>
                <div class="check-list" ref="scrollContainer" id="scrollContainer">
                    <div class="hr"></div>
                    <div class="li" v-for="(item,index) in checkedDay" :key="index">
                        <div class="check-bg" :class="{'checked-bg':index < numConsecutiveDays,'last-checked':index == (numConsecutiveDays -1)}"></div>
                    </div>
                </div>
                <div class="tip">
                    <img class="icon" src="@/assets/images/checkin/checked.png"/>{{$t('coin_treasure.checkin_tip')}}
                </div>
                <button  type="button" class="btn btn-primary btn-lg btn-checkin" :disabled="hasChecked" @click="postCheckIn()">
                    {{ hasChecked?$t("coin_treasure.signed_in"):$t("coin_treasure.sign_in") }}
                </button>
            </div>
            <div class="checkin-dialog" v-if="isShowPC">
                <div class="checkin-container checkin-container-pc">
                    
                    <div class="title">{{$t('coin_treasure.checkin_title')}}</div>
                    <div class="check-list" >
                        <div class="li check-bg-list" v-for="(item,index) in checkedDay" :key="index" :class="{'checked-bg-list':index < numConsecutiveDays}">
                            <div class="check-bg" :class="{'checked-bg':index < numConsecutiveDays,'last-checked':index == (numConsecutiveDays -1)}"></div>
                            <span class="day">+1</span>
                        </div>
                    </div>
                    <div class="tip">
                        <img class="icon" src="@/assets/images/checkin/checked.png"/>{{ $t("coin_treasure.signed_in_daynum",{numConsecutiveDays:numConsecutiveDays}) }}
                    </div>
                    <button  type="button" class="btn btn-primary  btn-lg btn-checkin" :disabled="hasChecked" @click="postCheckIn()">
                        {{ hasChecked?$t("coin_treasure.signed_in"):$t("coin_treasure.sign_in") }}
                    </button>
                    <div class="close" @click="isShowPC = false">
                        <svg t="1721053749092" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4569" width="200" height="200"><path d="M553.708426 511.998977l318.623781-318.623781c11.988032-11.988032 12.301164-31.112602 0.699941-42.714847l-1.399883-1.399883c-11.602246-11.602246-30.725792-11.288091-42.713824 0.700965L510.293637 468.584188 191.669856 149.96143c-11.988032-11.989055-31.112602-12.302187-42.714847-0.700965l-1.399883 1.400906c-11.602246 11.602246-11.288091 30.725792 0.700965 42.714847l318.623781 318.622758L148.255067 830.621734c-11.989055 11.989055-12.302187 31.112602-0.700965 42.714847l1.399883 1.399883c11.602246 11.602246 30.726815 11.288091 42.714847-0.699941l318.623781-318.623781 318.623781 318.623781c11.988032 11.989055 31.111578 12.302187 42.713824 0.700965l1.399883-1.399883c11.602246-11.602246 11.288091-30.726815-0.699941-42.714847L553.708426 511.998977z" fill="#ffffff" p-id="4570"></path></svg>
                    </div>
                </div>
            </div>
            <settings-panel-component v-bind:profile="profile" />
        </template>
        <div v-else>
            <loading-indicator />
        </div>
        <div v-if="isShowSuccess">
            <Dialog ref="checkInDialog" :data="checkinDetail"></Dialog>
        </div>
    </section>
</template>

<style scoped lang="less">
.hidden-input {
    display: none;
}
.page {
    background-color: #F3FBFD;
}

.user-top-header {
    margin: 40px auto;
    border-radius: 16px 16px 16px 16px;
}

.user-info-box {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.user-link {
    color: rgba(35, 116, 182, 1);
    font-size: 16px;
    display: block;
    margin-top: 17px;
}
.user-box {
    width: 100%;
    text-align: left;
}

.avatar {
    width: 100px;
    margin-right: 12px;
}

.username {
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 24px;
    color: #000000;
    line-height: 33px;
}
.user-name {
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 42px;
    color: #121836;
    line-height: 59px;
}

.vip-box {
    display: flex;
    align-items: center;
    justify-content: left;
    .btn-lucky {
        margin-left: 20px;        
    }
}

.vip-img {
    width: 60px;
    margin-right: 5px;
}
.vip-img-sm {
    width: 50px;
    margin-right: 5px;
}

.user-login-info {
    display: flex;
    align-items: center;
    min-width: 150px;
}

.vip-label-sm {
    justify-content: center;
    margin-left: 5px;
}

.user-info {
    display: flex;
    justify-content: center;
}


.user-info-item:first-child {
    margin-left: 0;
}

.info-des {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #000000;
    font-size: 14px;
    line-height: 22px;
    margin-right: 8px;
}

.info-value {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 16px;
    color: #18191C;
    line-height: 22px;
    margin-top: 17px;
}

.user-box-left {
    display: flex;
    align-items: center;
}

.login-time {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #999999;
    line-height: 18px;
}

.user-box-btn {
    border-radius: 20px;
    border: 1px solid #18191C;
    padding: 8px 12px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #000922;
    line-height: 10px;
    cursor: pointer;
}

.item-des {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 15px;
    color: #7F8490;
    line-height: 22px;
}

.item-value {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 15px;
    color: #18191C;
    line-height: 22px;
}
.btn-checkin {
    margin-left: 10px;
}
.btn-checkin:disabled {
    cursor: not-allowed;
    pointer-events: auto;
    opacity: 0.8;
    background: #ffb11a;
}
.checkin-box {
    display: flex;
    align-items:center
}
.checkin-box .desc{
    font-size: 12px;
    color: var(--primary-sec-base);
    padding-left: 10px;
}
.settings-page .top-header span.desc-mobile {
    display: none;
}
@media (max-width: 1024px) {
    .d-none {
        display: none !important;
    }

    .d-block {
        display: block !important;
    }
    .user-info {
        margin-top: 28px;
        display: flex !important;
        align-items: center;
        justify-content: space-between;
    }
}
.btn-checkin:focus,.btn-checkin:not(:disabled):not(.disabled):active:focus {
    box-shadow: none;
}
.checkin-container-mobile {
    background: rgba(153, 153, 153,0.2);
    display: none;
    margin: 0 auto;
    width: 335px;
    border-radius: 8px;
    text-align: center;
    padding: 20px 0 20px 16px;
    .desc {
        text-align: left;
        margin-bottom: 20px;
        font-size: 16px;
        color: #fff;
    }
    .tip {
        display: inline-block;
        margin: 30px 0 20px 0;
        padding-right: 16px;
        font-size: 12px;
        color: #fff;
        .icon {
            width: 12px;
            padding-right: 4px;
        }
    }
    .btn-checkin {
        padding: 0 65px;
        height: 40px;
        line-height: 40px;
        margin: 0 auto;
    }
    .btn-checkin:focus,.btn-checkin:not(:disabled):not(.disabled):active:focus {
        box-shadow: none;
    }
    .check-list { 
        width: 305px;
        position: relative;
        padding: 0 13px;
        box-sizing: border-box;
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        scrollbar-width: none;  /* Firefox */
        &::-webkit-scrollbar { 
            display: none;  /* Safari 和 Chrome */
        }
        .li {
            position: relative;
            z-index: 9        ;
            display: inline-block;
            padding: 10px 0;
            margin-right: 13px;
            &::after {
                content: "";
                background: #676767;
                position: absolute;
                left: 100%;
                top: 50%;
                transform: translateY(-50%);
                width: 14px;
                height: 4px;
            }
            &:before:first-child {
                border-radius: 4px;
                content: "";
                background: #676767;
                position: absolute;
                right: 100%;
                top: 50%;
                transform: translateY(-50%);
                width: 14px;
                height: 4px;
            }
            &:last-child::after {
                border-radius: 4px;
            }
        }
       
        .last-checked {
            &::after {
                content: "";
                position: absolute;
                top: -8px;
                right: 0px;
                background: url('@/assets/images/checkin/icon_start.png') no-repeat center;
                background-size: 9px 9px;
                width: 10px;
                height: 10px;
            }
        }
    }
    
}
.check-list {
    overflow-x: auto;
    white-space: nowrap; 
    text-align: right;
    .li {
        position: relative;
        z-index: 9;
        display: inline-block;
    }
    .check-bg {
            width: 44px;
            height: 44px;
            background: url('@/assets/images/checkin/check.png') no-repeat center;
            background-size: 44px 44px;
        }
        .checked-bg {
            background-image: url('@/assets/images/checkin/checked.png');
            position: relative;
           
        }
}
.checkin-dialog {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 9;
    background: rgba(0,0,0,0.2);
}
.checkin-container-pc {
    background: rgba(153, 153, 153,0.2);
    margin: 0 auto;
    width: 560px;
    max-width: 90%;
    background: #fff;
    border-radius: 12px;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    padding-bottom: 60px;
    .title {
        height: 64px;
        line-height: 64px;
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        background: linear-gradient( 33deg, #FEF1E8 0%, #FFF0BE 63%, #F6B689 95%);
        border-radius: 12px 12px 0 0;
    }
    .tip {
        padding: 16px 0;
        text-align: center;
        padding: 24px 0;
        color: #A4A2A2;
        .icon {
            height: 14px;
            vertical-align: middle;
            width: 14px;
            padding-right: 2px;
        }
    }
    .check-list {
        margin: 0 auto;
        margin-top: 60px;
        padding: 0 24px;
        width: 520px;
        padding-bottom: 20px;
        &::-webkit-scrollbar-thumb {
            background: rgba(0, 0, 0, 0.1);
            -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
        }
    }
    .li {
        width: 80px;
        height: 90px;
        margin-right: 30px;
        border-radius: 11px 11px 11px 11px;
        padding: 15px 24px 0 24px;
        box-sizing: border-box;
        text-align: center;
        display: inline-block;
        .check-bg {
            background-size: 28px 28px;
            width: 28px;
            height: 28px;
        }
        &.check-bg-list {
            background: #F7F7F7;
            background-size: 28px;
            &:last-child {
                margin-right: 0;
            }
            .day {
                display: block;
                margin-top: 15px;
            }
        }
        &.checked-bg-list {
            background: #FFFBE8;
            border-radius: 11px 11px 11px 11px;
            border: 1px solid #FFD341;
            .day {
                color: #FDA859;
            }
        }
    }
    .btn-checkin {
        width: 320px;
    }
    .close {
        position: absolute;
        right: 20px;
        top: 14px;
        cursor: pointer;
        border: 1px solid transparent;
        text-align: center;
        .icon {
            width: 26px;
            height: 26px;
        }
    }
}
@media (max-width:769px) {
    .avatar {
        width: 40px;
    }
    .user-box .checkin-box {
        display: block;
        .btn-checkin {
            display: none;
        }
    }
    .settings-page .top-header span.desc-mobile {
        display: block;
        font-size: 12px;
        color: var(--primary-sec-base);
        padding-left: 10px;
    }
    .checkin-container-mobile {
        display: block;
    }
}
</style>

<script>
import '@/assets/svg/icon-warning-sign.svg';
import '@/assets/svg/icon-checked.svg';
import '@/assets/svg/icon-arrow-right.svg';

import NoticeListComponent from './Components/Notices.vue';
import SettingsPanelComponent from './Components/SettingsPanel-4.1.vue';
import { setProfile } from 'utilities/helper';
import Dialog from './Components/CheckInDialog.vue';
import compressed from 'utilities/Compressed';

export default {
    components: { NoticeListComponent, SettingsPanelComponent,Dialog },
    data() {
        return {
            vipImage:null,
            profile: null,
            id_status: NaN,
            hasChecked:true,
            numConsecutiveDays:0,
            checkedDay:5,
            isShowPC: false,
            isShowSuccess: false,
            checkinDetail: {},
            bonus:0
        };
    },

    created() {
        this.getProfileAsync().catch((err) => {
            console.error(`ERROR: ${err}`);
        });
        this.getCheckIn();
    },
    mounted() {
        setTimeout(()=>{
            this.scrollToRight()
        },300)
    },
    computed:{
        userPhone() {
            return this.profile.phone?this.profile.phone:null
        },
        userEmail() {
            return this.profile.email?this.profile.email:null
        },
        userGa() {
            return this.profile.isgaSet
        },
    },
    methods: {
        hideDialog() {
            this.isShowSuccess = false;
        },
        checkinDialog() {
            this.isShowSuccess = true
            // 重新挂载组件
            this.$nextTick(() => {
                const modal = this.$refs.checkInDialog
                modal.showModal();
            })
        },
        triggerFileInput() {
            this.$refs.avatarInput.click();
        },
        uploadAvatar:async function(event) {
            const fileInput = this.$refs.avatarInput;
            const file = fileInput.files[0];
            if (file.size > 10 * 1024 * 1024) {
                fileInput.value = '';
                $.top_error(this.$t('idverification.err_too_big_file', { fileSize: Math.ceil(file.size / 1024.0) }));
                return false;
            }
            if (file) {
                try {
                    // No need to compress the photo if its size is smaller than 200k
                    let currentSize = parseInt(file['size']);
                    const formData = new FormData();
                    if (currentSize > 200 * 1024) {
                        const updated = await compressed.compressImg(file);
                        formData.append("avatar", updated);
                    }else{
                        formData.append("avatar", file);
                    }
                    $.callPostApi(this, '/api/v1/account/avatar',formData).then((response) => {
                        // update avatarUrl
                        if(response.errcode == 0){
                            this.$set(this.profile, "avatarUrl", response.data.AvatarUrl);
                            $.top_alert(this.$t('general.avatar_uploaded'));
                        }else{
                            $.top_error(response.errmsg);
                        }
                    }).catch((error) => {
                        console.error("Avatar upload failed:", error);
                        $.top_error(this.$t('general.avatar_upload_failed'));
                    });
                } catch (err) {
                    // The error could be ignored.
                    console.error(`Failed to compress avatar: ${err}`);
                }
            }
        },
        scrollToRight() {
            const container = $("#scrollContainer");
            container.scrollLeft(container[0].scrollWidth);
        },
        getCheckIn() {
            if(this.sysconfig.checkinEnabled) {
                $.callGetApi(this, '/api/v1/checkin').then((res) => {
                    const {errcode,data} = res
                    if(errcode === 0 && data) {
                        this.hasChecked = data.checkedIn;
                        this.numConsecutiveDays = data.numConsecutiveDays
                        this.checkinDetail = data
                        if(this.bonus) { //判断签到奖励金额是否大于0
                            this.checkinDetail.bonus = this.bonus
                        }
                        if(data.checkedIn) {
                            this.checkedDay = data.numConsecutiveDays + 2
                        } else {
                            this.checkedDay = data.numConsecutiveDays + 3
                        }
                        if(this.checkedDay < 5) {
                            this.checkedDay = 5
                        }
                    }
                });
            }
        },
        postCheckIn(){
            if(this.hasChecked === false) {
                $.callPostApi(this, '/api/v1/checkin').then((res) => {
                    const {errcode,data} = res
                    if(errcode === 0 && data) {
                        this.bonus = data.bonus
                        this.getCheckIn();
                        this.checkinDialog();
                    } else {
                        this.hasChecked = false
                    }
                });
            }
        },
        luckyDraw(){
            this.$router.push({ path: '/luckyDraw' });
        },
        getProfileAsync: async function () {
            const self = this;
            const json = await $.callPostApi(self, '/api/v1/account/profile');

            // Could be undefined if authentication is required.
            if (json && json.errcode === 0 && json.data) {
                setProfile(json.data);
                let profile = Object.freeze(json.data);

                // Read ID verification status
                const resp = await $.callGetApi(self, '/api/v1/identityverification');
                if (resp) {
                    if (typeof resp.data !== 'number' || resp.data < 0) throw new Error('Invalid id-verification status response.');
                    self.id_status = resp.data;
                };
                self.profile = JSON.parse(JSON.stringify(profile));
            }
        },



        formatString(str) {
            // get str length
            const length = str.length;
            // Define the length of prefixes and suffixes to retain
            const prefixLength = 3;
            const suffixLength = 2;
            // Determine whether the string is long enough
            if (length <= prefixLength + suffixLength) {
                return str;
            }
            // Get prefix and suffix
            const prefix = str.slice(0, prefixLength);
            const suffix = str.slice(length-suffixLength);

            // Final result of splicing
            return `${prefix}***${suffix}`;
        }
    }
};
</script>
